import React from "react"
import { graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const intl = useIntl()

  const medias = data.medias.edges;

  return (
    <Layout page='home' bgColorDark={true} homeHeader={data.headboard.childImageSharp.fluid}>
      <SEO title="Elevage" keywords={[`Elevage`, `Cedrenchante`, `chiens`, `chats`, `chiots`, `chatons`, `berger des shetland`, `chihuahua`, `epagneul tibetain`, `british shorthair`]} />

      <h1>{intl.formatMessage({ id: "HOME.TITLE_NEWS" })}</h1>
      <div className="index_fb-container">
      	<div class="fb-page" data-href="https://www.facebook.com/cedrenchante" data-tabs="timeline" data-width="" data-height="" data-small-header="true" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/cedrenchante" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/cedrenchante">Elevage du Cèdre Enchanté</a></blockquote></div>
      </div>
      <h1>{intl.formatMessage({ id: "HOME.TITLE_MEDIAS" })}</h1>
      <ul className="index_medias">
        {medias.map((media, index) => (
          <li key={index}>
          <Img 
              fluid={media.node.childImageSharp.fluid}
              style={{height: "50px", width:`${media.node.childImageSharp.fluid.aspectRatio*50}px`}}

          /></li>
        ))}
      </ul>
      
      <div className="index_videos">
        <iframe 
          width="560" height="315"
          src="https://www.youtube-nocookie.com/embed/hh7RjFU0TwY" 
          title="Les animaux de la 8"
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen/>

        <iframe  
          width="560" height="315"
          src="https://www.youtube-nocookie.com/embed/g4QSk_4ZcU8" 
          title="Les animaux de la 8"
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen/>

        <iframe
          width="560" height="315"
          src="https://www.youtube-nocookie.com/embed/dzOW4y_0rFg" 
          title="Les animaux de la 8"
          frameBorder="0" 
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen/>

        <iframe 
          width="640" height="360"
          src="https://www.dailymotion.com/embed/video/k5sFErU7xu3eKovXt45" 
          title="TFou d'animaux"
          frameBorder="0"
          allow="autoplay"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen/>

      </div>
        
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query IndexQuery{
    medias: allFile(filter: { absolutePath: { regex: "/medias/" }, childImageSharp: {fluid: {presentationHeight: {gte: 10}}} }) {
      edges {
          node {
              childImageSharp {
                  fluid(maxHeight: 500) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      presentationWidth
                      presentationHeight
                      aspectRatio
                  }
              }
          }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    },
    headboard: file(relativePath: { eq: "headboard-cedrenchante.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }`;